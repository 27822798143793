@import "../../assets/styles/utils";

.full-banner{
    .banner-text{
        position: relative;
        .en-title{
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            font-family: 'roboto-b';
            color: #f2f2f2;
            line-height: .7;
            text-transform: uppercase;
            @include res(font-size,300px, 100 / 300);
        }
        .cn-title{
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            transform: translateY(-50%);
            align-items: center;
            justify-content: space-between;
            @include res(display,flex,(xs:block));
            @include res(padding-left,150px, 15 / 150);
            @include res(padding-right,150px, 15 / 150);
            .t{
                font-weight: bold;
                color: $color-main;
                @include res(margin-top,0,(xs:5px));
                @include res(font-size,56px, 26 / 56);
            }
        }
    }
    .banner-img{
        position: relative;
        overflow: hidden;
        @include res(height,600px, 160 / 600);
        b{
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 130%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}