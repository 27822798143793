@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto-b';
  src: url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.ttf') format('truetype'),
    url('../fonts/Roboto-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

.root-cont{
  position: relative;
  z-index: 1;
  background-color: #fff;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}

//主体内容部分宽度
.container{
  width: 100%;
  box-sizing: border-box;
  @include res(padding-left, 50px, 10 / 50);
  @include res(padding-right, 50px, 10 / 50);
}

.location{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-desc;
    span{
        @include res(margin-left,5px);
        @include res(margin-right,5px);
    }
    a{
        transition: all .3s;
        &:hover{
            color: $color-main;
        }
    }
}

.common-more{
  &.hasbg{
      a,button{
          background-color: $color-main-light;
          color: #fff;
          border: 1px solid $color-main-light;
          &::before,&::after{
              background-color: $color-main;
          }
      }
  }
    a,button{
        border: 1px solid $color-main;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-main;
        position: relative;
        transition: all .3s $anime-bezier;
        @include res(padding-left,30px, 15 / 30);
        @include res(padding-right,30px, 15 / 30);
        @include res(padding-top,20px, 10 / 20);
        @include res(padding-bottom,20px, 10 / 20);
        @include res(width,250px, 150 / 250);
        &:hover{
            color: #fff;
            &::before,&::after{
                transform: scaleX(1);
                transform-origin: left;
            }
        }
        &::before{
            content: '';
            display: block;
            width: calc(100% + 2px);
            height: calc(50% + 1px);
            position: absolute;
            top: -1px;
            left: -1px;
            background-color: $color-main-light;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform .3s $anime-bezier;
        }
        &::after{
            content: '';
            display: block;
            width: calc(100% + 2px);
            height: calc(50% + 1px);
            position: absolute;
            bottom: -1px;
            left: -1px;
            background-color: $color-main-light;
            transform: scaleX(0);
            transition: transform .3s $anime-bezier;
            transform-origin: right;
            transition-delay: .1s;
        }
        span{
            position: relative;
            z-index: 1;
        }
        .iconfont{
            position: relative;
            z-index: 1;
            @include res(font-size,20px, 20 / 20);
        }
    }
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'roboto-b';
  @include res(padding-bottom,150px, 50 / 150);
  @include res(margin-top,30px, 10 / 30);
  @include res(font-size,18px, 14 / 18);
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .3s;
    @include res(width,46px, 36 / 46);
    @include res(height,46px, 36 / 46);
    &:not(:first-child){
      margin-left: 5px;
    }
    &:hover{
      color: $color-main;
    }
    &.active{
      background-color: $color-main;
      color: #fff;
    }
  }
}

.commom-job{
  @include res(padding-left,185px, 0 / 185);
  @include res(padding-right,535px, 0 / 535);
  .job-desc{
    color: $color-main;
    @include res(font-size,20px, 14 / 20);
    @include res(line-height,2, 1.6 / 2);
    @include res(margin-bottom,65px, 20 / 65);
  }
}