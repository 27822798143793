@import "../../assets/styles/utils";

.footer{
    // background: url(./images/footer_bg.jpg) no-repeat right center #10387f;
    // background-size: auto 100%;
    background:#2b6caa;
    color: #fff;
    @include res(text-align,null,(sm:center));
    @include res(padding-top,50px, 15 / 50);
    @include res(padding-bottom,80px, 15 / 80);
    position: relative;
    &::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #081f49;
        opacity: .5;
        z-index: 0;
        @include res(display,none,(sm:block));
    }
    &.fixed-footer{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }
    #particles-js{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .backtop{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #78eaff;
        color: $color-main;
        position: absolute;
        cursor: pointer;
        transition: all .3s;
        z-index: 2;
        @include res(top,50px, 10 / 50);
        @include res(right,50px, 10 / 50);
        @include res(width,66px, 40 / 66);
        @include res(height,66px, 40 / 66);
        &:hover{
            background-color: $color-main-light;
            color: $color-main-light;
            transform: translateY(-5px);
        }
        .iconfont{
            color: #002f6c;
            @include res(font-size,30px, 20 / 30);
        }
    }
    .footer-link{
        position: relative;
        z-index: 1;
        @include res(display,block,(sm:none));
        @include res(margin-bottom,65px, 15 / 65);
        a{
            display: inline-block;
            transition: all .3s;
            &:not(:last-child){
                @include res(margin-right,40px, 10 / 40);
            }
            &:hover{
                transform: translateX(3px);
            }
        }
    }
    .footer-cont{
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        @include res(display,flex,(sm:block));
        .footer-left{
            flex-shrink: 0;
            @include res(width,40%,(sm:100%));
            .footer-logo{
                @include res(margin-bottom,35px, 10 / 35);
                img{
                    display: block;
                    width: 70%;
                    height: auto;
                    max-width: 410px;
                    @include res(margin,null,(sm:0 auto));
                }
            }
            .website{
                @include res(margin-bottom,80px, 10 / 80);
                a{
                    display: inline-block;
                    transition: all .3s;
                    &:hover{
                        transform: translateX(3px);
                    }
                }
            }
            .copyright{
                @include res(display,block,(sm:none));
            }

        }
        .footer-right{
            flex-shrink: 0;
            @include res(width,53%,(sm:100%));
            .phone{
                @include res(font-size,46px, 20 / 46);
                @include res(margin-bottom,25px, 10 / 25);
            }
            .address{
                @include res(font-size,18px, 14 / 18);
            }
            .webmap{
                align-items: center;
                justify-content: space-between;
                @include res(display,flex,(sm:none));
                @include res(margin-top,25px, 10 / 25);
                &::before{
                    content: '';
                    display: block;
                    flex-grow: 1;
                    height: 1px;
                    background-color: #fff;
                }
                a{
                    margin-left: 10px;
                }
            }
            .copyright{
                @include res(margin-top,25px, 10 / 25);
                @include res(display,none,(sm:block));
            }
        }
    }
}
