@import "../../assets/styles/utils";

header{
    &.scroll-header{
        .header-placeholder{
            @include res(height,80px,60 / 80);
        }
        .header{
            @include res(height,80px,60 / 80);
            .header-cont{
                @include res(height,80px,60 / 80);
            }
        }
        .mob-nav{
            @include res(padding-top,80px,60 / 80);
        }
    }
    .root-mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        z-index: 10;
        display: none;
    }
    .header-placeholder{
        transition: all .3s;
        @include res(height,$header-height-base,$header-height-ratio);
    }
    .header{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        overflow: hidden;
        box-sizing: content-box;
        transition: transform .3s;
        @include res(height,$header-height-base,$header-height-ratio);
        &.hide{
            transform: translateY(-100%);
        }
        .header-cont{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            transition: all .3s;
            @include res(height,$header-height-base,$header-height-ratio);
        }
        .left-logo{
            height: 100%;
            display: flex;
            align-items: center;
            a{
                display: block;
                height: 50%;
                position: relative;
                max-height: 58px;
                img{
                    display: block;
                    height: 100%;
                    width: auto;
                    transition: all .3s;
                    &.white-logo{
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                    }
                }
            }
        }
        .right-nav{
            height: 100%;
            @include res(display,block,(sm:none));
            ul{
                display: flex;
                align-items: stretch;
                justify-content: flex-end;
                height: 100%;
                li{
                    height: 100%;
                    &:nth-child(2){
                      .sub-nav{
                        .sub-left{
                          display: none;
                          &.select1{
                            // opacity: 1;
                            // visibility: visible;
                            display:flex;
                          }
                          &.select2{
                            // opacity: 0;
                            // visibility: hidden;
                            display: none;
                          }
                          &.select3{
                            // opacity: 0;
                            // visibility: hidden;
                            display: none;
                          }
                        }
                      }
                    }
                    &.active{
                        .nav-title{
                            &::after{
                                opacity: 1;
                            }
                        }
                    }
                    &.not-active{
                        .nav-title{
                            &::after{
                                opacity: 0;
                            }
                        }
                    }
                    &.hover{
                        .nav-title{
                            &::after{
                                opacity: 1;
                            }
                        }
                        .sub-nav{
                            z-index: 1;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .nav-title{
                        height: 100%;
                        position: relative;
                        &::after{
                            content: '';
                            display: block;
                            border-radius: 50%;
                            background: $color-main;
                            position: absolute;
                            top: 65%;
                            left: calc(50% - 2px);
                            z-index: 0;
                            transition: all .3s;
                            opacity: 0;
                            @include res(width,4px);
                            @include res(height,4px);
                        }
                        a{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .3s;
                            position: relative;
                            z-index: 1;
                            color: $color-text;
                            @include res(font-size,18px, 14 / 18);
                            @include res(padding-right,20px, 5 / 20);
                            @include res(padding-left,20px, 5 / 20);
                        }
                    }
                    .sub-nav{
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100vw;
                        box-sizing: border-box;
                        align-items: stretch;
                        justify-content: space-between;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s ease .25s;
                        z-index: 0;
                        @include res(display,flex);
                        @include res(padding-left,50px, 10 / 50);
                        @include res(padding-right,50px, 10 / 50);
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            background-color: rgba(255,255,255,.3);
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            @include res(height,3px, 1 / 3);
                        }
                        .sub-left{
                            flex-shrink: 0;
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            box-sizing: border-box;
                            position: relative;
                            color: #fff;
                            @include res(padding-left,125px,30 / 125);
                            @include res(height,315px,200 / 315);
                            @include res(width,44.23%, 34 / 44.23);
                            &::after{
                                content: '';
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(3,28,51,.7);
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 0;
                            }
                            h1,h2{
                                position: relative;
                                z-index: 1;
                            }
                            h1{
                                font-weight: bold;
                                @include res(font-size,36px, 18 / 36);
                                @include res(margin-bottom,12px, 6 / 12);
                            }
                            h2{
                                opacity: .8;
                                @include res(font-size,18px, 14 / 18);
                            }
                        }
                        .sub-right{
                            flex-shrink: 0;
                            box-sizing: border-box;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            @include res(width,55.77%, 66 / 55.77);
                            @include res(padding-left,60px, 10 / 60);
                            @include res(padding-right,60px, 10 / 60);
                            @include res(padding-top,40px, 10 / 40);
                            @include res(padding-bottom,40px, 10 / 40);
                            a{
                                border-bottom: 1px solid #cccccc;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                @include res(width,47%);
                                @include res(padding-bottom,15px, 10 / 15);
                                &:hover{
                                    .item-icon{
                                        border: 1px solid $color-main;
                                        background-color: $color-main;
                                        .iconfont{
                                            color: #fff;
                                            transform: translateX(100%);
                                        }
                                    }
                                }
                                .item-text{
                                    h1{
                                        color: $color-main;
                                        font-weight: bold;
                                        margin-bottom: 5px;
                                        @include res(font-size,24px, 16 / 24);
                                    }
                                    h2{
                                        color: $color-desc;
                                        @include res(font-size,18px, 14 / 18);
                                    }
                                }
                                .item-icon{
                                    flex-shrink: 0;
                                    border-radius: 50%;
                                    border: 1px solid #b2b2b2;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    overflow: hidden;
                                    transition: all .3s;
                                    @include res(width,50px, 40 / 50);
                                    @include res(height,50px, 40 / 50);
                                    .iconfont{
                                        flex-shrink: 0;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        color: $color-main;
                                        transition: all .3s;
                                        @include res(font-size,30px, 20 / 30);
                                        @include res(width,50px, 40 / 50);
                                        @include res(height,50px, 40 / 50);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .open-menu{
            padding: 15px;
            margin-right: -10px;
            @include res(display,none,(sm:block));
        }
    }
    .mob-nav{
        position: fixed;
        top: 0;
        width: 100%;
        left: 100%;
        height: 100vh;
        z-index: 99;
        overflow-y: auto;
        max-width: 425px;
        background-color: $color-main;
        color: #fff;
        transition: all .3s;
        @include res(padding-top,$header-height-base,$header-height-ratio);
        &.active{
            transform: translateX(-100%);
        }
        ul{
            @include res(padding-left,30px, 15 / 30);
            @include res(padding-right,30px, 15 / 30);
            @include res(padding-top,30px, 15 / 30);
            @include res(padding-bottom,30px, 15 / 30);
            li{
                &.active{
                    // padding-bottom: 10px;
                    border-bottom: 1px solid rgba(255,255,255,.3);
                    .nav-title{
                        .iconfont{
                            transform: rotate(90deg);
                        }
                    }
                }
                .nav-title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(255,255,255,.5);
                    a{
                        display: block;
                        font-size: 16px;
                        flex-grow: 1;
                        @include res(padding-top,15px);
                        @include res(padding-bottom,15px);
                    }
                    .iconfont{
                        font-size: 20px;
                        transition: all .3s;
                        width: 50px;
                        display: none;
                        align-items: center;
                        justify-content: center;
                        &.show{
                            display: flex;
                        }
                    }
                }
                .sub-nav{
                    display: none;
                    a{
                        display: block;
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}
