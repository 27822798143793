@import "../../assets/styles/utils";

.half-banner{
    position: relative;
    @include res(height,695px, 160 / 695);
    @include res(margin-bottom, 230px, 50 / 230);
    .location{
        position: absolute;
        z-index: 1;
        @include res(left,50px, 10 / 50);
        @include res(top,80px, 10 / 80);
    }
    .cont{
        height: 100%;
        align-items: center;
        justify-content: space-between;
        @include res(display,flex);
        .left-title{
            flex-shrink: 0;
            box-sizing: border-box;
            font-weight: bold;
            color: $color-main;
            @include res(width,34%);
            @include res(padding-left,50px, 10 / 50);
            @include res(padding-right,10px, 10 / 10);
            @include res(font-size,56px, 24 / 56);
        }
        .right-img{
            flex-shrink: 0;
            height: 100%;
            position: relative;
            overflow: hidden;
            @include res(width,66%);
            b{
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .en-title{
        width: 100%;
        position: absolute;
        bottom: 0;
        font-family: 'roboto-b';
        line-height: .8;
        white-space: nowrap;
        transform: translateY(50%);
        overflow: hidden;
        left: 0;
        // @include res(width,calc(100% + 20px),(md:calc(100% + 10px),xs:calc(100% + 5px)));
        @include res(font-size,300px, 50 / 300);
        // @include res(left,-20px,(md:-10px,xs:-5px));
        .left-title{
            width: 100%;
            position: absolute;
            width: 34%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            overflow: hidden;
            bottom: 0;
            left: 0;
            z-index: 2;
            color: #E6EEF6;
            text-shadow: 0 0 1px #E6EEF6;
            // @include res(width,calc(34% + 20px),(md:calc(34% + 10px),xs:calc(34% + 5px)));
        }
        .half-title{
            width: 100%;
            position: relative;
            .title-blue{
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                color: rgba(9,89,163,.5);
            }
            .title-gray{
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                height: 50%;
                position: absolute;
                bottom: 0;
                left: 0;
                color: #E6EEF6;
                z-index: 1;
                overflow: hidden;
                text-shadow: 0 0 1px #E6EEF6;
            }
        }
    }
}